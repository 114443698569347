export const hostProjectSchema = {
  type: "object",
  properties: {
    name: {
      title: "The name for the project",
      type: "string",
    },
    domain: {
      title: "The domain name (optional).",
      type: "string",
      default: "",
    },
    activate_apis: {
      title: "The list of apis to activate within the project",
      type: "array",
      items: {
        type: "string",
      },
      default: [
        "compute.googleapis.com",
        "container.googleapis.com",
        "dataflow.googleapis.com",
        "bigquery.googleapis.com",
      ],
    },
    labels: {
      type: "array",
      title: "Map of labels for project",
      items: {
        type: "object",
        properties: {
          key: {
            type: "string",
          },
          value: {
            type: "string",
          },
        },
      },
    },
    lien: {
      type: "boolean",
      title: "Add a lien on the project to prevent accidental deletion",
      default: true,
    },
  },
  required: ["name"],
};
