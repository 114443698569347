export const instanceSchema = {
  title: "Instance From Template",
  type: "object",
  properties: {
    hostname: {
      title: "Instance Host Name",
      type: "string",
    },
    add_hostname_suffix: {
      type: "boolean",
      default: true,
      title: "Add Hostname Suffix",
      description: "Adds a suffix to the hostname",
    },
    region: {
      title: "Region",
      type: "string",
      default: "null",
    },
    num_instances: {
      title: "Number of Instances",
      type: "string",
      default: "1",
    },
    zone: {
      title: "Zone where instances should be deployed",
      type: "string",
      default: null,
    },
    subnetwork: {
      type: "string",
      title: "Subnetwork self_link",
      default: "",
      description:
        "Subnetwork self_link in the format projects/<PROJECT_ID>/regions/<REGION>/subnetworks/<SUBNET_NAME>. This overrides template value",
    },
    access_config: {
      type: "array",
      title: "Access Config",
      description:
        "IPs via which the VM instance can be accessed via the Internet. Leave blank for private access. This overrides template value",
      items: {
        type: "object",
        properties: {
          nat_ip: {
            title: "NAT IP",
            type: "string",
          },
          network_tier: {
            title: "Network Tier",
            type: "string",
            enum: ["STANDARD", "PREMIUM"],
            default: "PREMIUM",
          },
        },
      },
      default: [],
    },
  },
  required: ["hostname"],
};
