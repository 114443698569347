export const serviceAccountsSchema = {
  title: "Service Accounts",
  type: "object",
  properties: {
    service_accounts: {
      type: "array",
      title: "Names of the service accounts to be created",
      items: {
        type: "string",
      },
    },
  },
};
