export const orgFolderSchema = {
  type: "object",
  properties: {
    name: {
      title: "Folder Name",
      type: "string",
      description: "Folder name",
    },
    /*
    parent: {
      type: "string",
      title: "Parent Folder or Organization id",
      description: "Parent",
      default: "",
      examples: ["organizations/111223333"],
    },*/
  },
  required: ["name"],
};
