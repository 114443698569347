export const migSchema = {
  title: "Regional Managed Instance Group",
  type: "object",
  properties: {
    hostname: {
      title: "Hostname prefix for instances",
      type: "string",
      default: "default",
    },
    mig_name: {
      title: "Managed Instance Group Name",
      description:
        "Managed instance group name. When variable is empty, name will be derived from var.hostname.",
      type: "string",
      default: "",
    },
    region: {
      title: "Region",
      type: "string",
    },
    target_size: {
      title: "Target Size",
      type: "string",
      default: "1",
      description:
        "The target number of running instances for this managed instance group. This value should always be explicitly set unless this resource is attached to an autoscaler, in which case it should never be set.",
    },
    target_pools: {
      type: "array",
      title: "Target Pools",
      description: "The target load balancing pools to assign this group to.",
      items: {
        type: "string",
      },
    },
    distribution_policy_zones: {
      title: "Distribution Policy Zones",
      type: "array",
      description:
        "The distribution policy, i.e. which zone(s) should instances be create in. Default is all zones in given region.",
      items: {
        type: "string",
      },
    },
    stateful_disks: {
      title: "Stateful Disks",
      type: "array",
      description:
        "Disks created on the instances that will be preserved on instance delete. https://cloud.google.com/compute/docs/instance-groups/configuring-stateful-disks-in-migs",
      items: {
        type: "object",
        properties: {
          device_name: {
            title: "Device name",
            type: "string",
          },
          delete_rule: {
            title: "Delete Rule",
            type: "string",
          },
        },
      },
      default: [],
    },
    update_policy: {
      type: "array",
      title: "Update Policy",
      description:
        "The rolling update policy. https://www.terraform.io/docs/providers/google/r/compute_region_instance_group_manager.html#rolling_update_policy",
      items: {
        type: "object",
        properties: {
          max_surge_fixed: {
            title: "Max Surge Fixed",
            type: "string",
          },
          instance_redistribution_type: {
            title: "Instance Redistribution Type",
            enum: ["PROACTIVE", "NONE"],
            default: "NONE",
            type: "string",
          },
          max_surge_percent: {
            title: "Max Surge Percent",
            type: "number",
            default: 20,
          },
          max_unavailable_fixed: {
            title: "Max Unavailable Fixed",
            type: "number",
            default: 2,
          },
          max_unavailable_percent: {
            title: "Max Unavailable Fixed",
            type: "number",
            default: 2,
          },
          min_ready_sec: {
            title: "Min Ready Seconds",
            type: "number",
            default: 50,
          },
          minimal_action: {
            type: "string",
            title: "Minimal Action",
            enum: ["RESTART", "REPLACE"],
            default: "REPLACE",
          },
          type: {
            type: "string",
            title: "The type of update process",
            enum: ["PROACTIVE", "OPPORTUNISTIC"],
            default: "PROACTIVE",
          },
        },
      },
    },
    health_check_name: {
      type: "string",
      description:
        "Health check name. When variable is empty, name will be derived from var.hostname.",
      default: "",
    },
    health_check: {
      title: "Health Check",
      description:
        "Health check to determine whether instances are responsive and able to do work",
      type: "object",
      properties: {
        type: {
          title: "Type",
          enum: ["tcp", "http", "https"],
          type: "string",
          default: "http",
        },
        initial_delay_sec: {
          title: "Initial Delay in Seconds",
          type: "number",
          default: 30,
        },
        check_interval_sec: {
          title: "Check Interval in Seconds",
          type: "number",
          default: 30,
        },
        healthy_threshold: {
          title: "Healthy Threshold",
          type: "number",
          default: 1,
        },
        timeout_sec: {
          title: "Timoue Seconds",
          type: "number",
          default: 10,
        },
        unhealthy_threshold: {
          title: "Unhealthy threshold",
          type: "number",
          default: 5,
        },
        response: {
          title: "Health Check Response",
          type: "string",
          default: "",
        },
        proxy_header: {
          title: "Proxy Header",
          type: "string",
          default: "NONE",
        },
        port: {
          title: "Port",
          type: "number",
          default: 80,
        },
        request: {
          title: "Request",
          type: "string",
          default: "",
        },
        request_path: {
          title: "Request path",
          type: "string",
          default: "/",
        },
        host: {
          title: "Host header",
          type: "string",
          default: "",
        },
      },
    },
    wait_for_instances: {
      type: "boolean",
      default: false,
      title: "Wait For Instances",
      description:
        "Whether to wait for all instances to be created/updated before returning. Note that if this is set to true and the operation does not succeed, Terraform will continue trying until it times out.",
    },
    named_ports: {
      description:
        "Named name and named port. https://cloud.google.com/load-balancing/docs/backend-service#named_ports",
      type: "array",
      default: [],
      title: "Named Ports",
      items: {
        type: "object",
        properties: {
          name: {
            title: "Name",
            type: "string",
          },
          port: {
            title: "Port",
            type: "number",
          },
        },
        required: ["name", "port"],
      },
    },
    autoscaler_name: {
      title: "Autoscaler name",
      type: "string",
      default: "",
      description:
        "Autoscaler name. When variable is empty, name will be derived from var.hostname.",
    },
    autoscaling_enabled: {
      title: "Autoscalling enabled",
      type: "boolean",
      default: false,
      description: "Creates an autoscaler for the managed instance group",
    },
    max_replicas: {
      type: "number",
      default: 10,
      title: "Maximum Replicas",
      description:
        "The maximum number of instances that the autoscaler can scale up to. This is required when creating or updating an autoscaler. The maximum number of replicas should not be lower than minimal number of replicas.",
    },
    min_replicas: {
      type: "number",
      default: 2,
      title: "Minimum Number of replicas",
      description:
        "The minimum number of replicas that the autoscaler can scale down to. This cannot be less than 0.",
    },
    cooldown_period: {
      type: "number",
      default: 60,
      title: "Cooldown Period",
      description:
        "The number of seconds that the autoscaler should wait before it starts collecting information from a new instance.",
    },
    autoscaling_cpu: {
      title: "Autoscalling CPU",
      description:
        "Autoscaling, cpu utilization policy block as single element array. https://www.terraform.io/docs/providers/google/r/compute_autoscaler.html#cpu_utilization",
      type: "array",
      default: [],
      items: {
        type: "object",
        properties: {
          target: {
            title: "Target",
            type: "number",
          },
        },
        required: ["target"],
      },
    },
    autoscaling_metric: {
      description:
        "Autoscaling, metric policy block as single element array. https://www.terraform.io/docs/providers/google/r/compute_autoscaler.html#metric",
      title: "Autoscaling Metric",
      type: "array",
      default: [],
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
          },
          target: {
            type: "number",
          },
          type: {
            type: "string",
            enum: ["GAUGE", "DELTA_PER_SECOND", "DELTA_PER_MINUTE"],
          },
        },
        required: ["name"],
      },
    },
    autoscaling_lb: {
      description:
        "Autoscaling, load balancing utilization policy block as single element array. https://www.terraform.io/docs/providers/google/r/compute_autoscaler.html#load_balancing_utilization",
      title: "Autoscaling Load Balancer",
      type: "array",
      default: [],
      items: {
        type: "object",
        properties: {
          target: {
            type: "number",
          },
        },
        required: ["target"],
      },
    },
    autoscaling_scale_in_control: {
      description:
        "Autoscaling, scale-in control block. https://www.terraform.io/docs/providers/google/r/compute_autoscaler.html#scale_in_control",
      type: "object",
      title: "Autoscaling scale in control",
      default: {
        fixed_replicas: null,
        percent_replicas: null,
        time_window_sec: null,
      },
      properties: {
        fixed_replicas: {
          type: ["number", "null"],
          title: "Fixed Replicas",
          default: null,
        },
        percent_replicas: {
          type: ["number", "null"],
          title: "Percent Replicas",
          default: null,
        },
        time_window_sec: {
          type: ["number", "null"],
          title: "Time Window in Seconds",
          default: null,
        },
      },
    },
    mig_timeouts: {
      description:
        "Times for creation, deleting and updating the MIG resources. Can be helpful when using wait_for_instances to allow a longer VM startup time. ",
      type: "object",
      properties: {
        create: {
          type: "string",
          default: "5m",
        },
        update: {
          type: "string",
          default: "5m",
        },
        delete: {
          type: "string",
          default: "15m",
        },
      },
    },
  },
  required: ["mig_name", "region"],
};
