export const folderSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
      description: "Name",
      title: "Folder Name",
    },
  },
  required: ["name"],
};
