import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import InfoIcon from "@material-ui/icons/Info";
import EmailIcon from "@material-ui/icons/Email";
import Divider from "@material-ui/core/Divider";
import { useAuth } from "../contexts/AuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 480,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Profile() {
  const classes = useStyles();
  const { currentUser } = useAuth();

  return (
    <List className={classes.root}>
      <ListItem>
        <ListItemAvatar>
          <Avatar src={currentUser.photoURL} />
        </ListItemAvatar>
        <ListItemText primary="Name:" secondary={currentUser.displayName} />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <EmailIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Email:" secondary={currentUser.email} />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <InfoIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Google ID:"
          secondary={currentUser.providerData[0].uid}
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <InfoIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="UID:" secondary={currentUser.uid} />
      </ListItem>
    </List>
  );
}
