export const httpsLBSchema = {
  title: "External HTTP(S) Load Balancer",
  type: "object",
  properties: {
    name: {
      title: "Name",
      description:
        "Name for the forwarding rule and prefix for supporting resources",
      type: "string",
    },
    create_address: {
      type: "boolean",
      title: "Create IPv4 Address",
      description: "Create a new global IPv4 address",
      default: true,
    },
    address: {
      type: ["string", "null"],
      title: "Address",
      description: "Existing IPv4 address to use (the actual IP address value)",
      default: null,
    },
    enable_ipv6: {
      type: "boolean",
      title: "Enable IPv6 Address",
      description: "Enable IPv6 address on the CDN load-balancer",
      default: false,
    },
    create_ipv6_address: {
      type: "boolean",
      title: "Create IPv6 Address",
      description:
        'Allocate a new IPv6 address. Conflicts with "ipv6_address" - if both specified, "create_ipv6_address" takes precedence.',
      default: false,
    },
    ipv6_address: {
      type: ["null", "string"],
      default: null,
      description:
        "An existing IPv6 address to use (the actual IP address value)",
      title: "IPv6 Address",
    },
    backends: {
      title: "Backends",
      description: "List of backends for the load balancer",
      type: "array",
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
            title: "Backend Name",
          },
          protocol: {
            type: "string",
            enum: ["HTTPS", "HTTP", "HTTP2"],
          },
          port: {
            type: "number",
          },
          port_name: {
            type: "string",
          },
          description: {
            type: "string",
            default: "",
          },
          enable_cdn: {
            type: "boolean",
            default: false,
            title: "Enable CDN",
          },
          security_policy: {
            type: ["string", "null"],
            default: null,
            title: "Security Policy",
          },
          session_affinity: {
            type: ["string", "null"],
            default: null,
            title: "Session Affinity",
          },
          affinity_cookie_ttl_sec: {
            type: ["number", "null"],
            default: null,
            title: "Affinity Cookie TTL Seconds",
          },
          timeout_sec: {
            title: "Timout Seconds",
            type: ["number", "null"],
            default: 10,
          },
          connection_draining_timeout_sec: {
            title: "Connection Draining timout seconds",
            type: ["number", "null"],
            default: null,
          },
          health_check: {
            title: "Backend Health Check",
            type: "object",
            properties: {
              check_interval_sec: {
                type: ["number", "null"],
                default: null,
                title: "Check interval in seconds",
              },
              timeout_sec: {
                type: ["number", "null"],
                default: null,
                title: "Timoue in seconds",
              },
              healthy_threshold: {
                type: ["number", "null"],
                default: null,
                title: "Healthy threshold",
              },
              unhealthy_threshold: {
                type: ["number", "null"],
                default: null,
                title: "Unhealthy threshold",
              },
              request_path: {
                type: ["string"],
                default: "",
                title: "Request Path",
              },
              port: {
                type: ["number"],
                title: "Health Check Port",
              },
              host: {
                type: ["string", "null"],
                title: "Host Header value",
                default: null,
              },
              logging: {
                type: "boolean",
                default: false,
                title: "Log healthcheck",
              },
            },
            required: ["port"],
          },
          log_config: {
            type: "object",
            title: "Log Config",
            properties: {
              enable: {
                type: "boolean",
                title: "Enable",
                default: false,
              },
              sample_rate: {
                type: ["number", "null"],
                title: "Log Sample Rate",
                default: null,
              },
            },
          },
          groups: {
            title: "Groups",
            description:
              "Definitions for managed instance groups. Instance groups should already be deployed",
            type: "array",
            items: {
              type: "object",
              properties: {
                group: {
                  title: "Managed Instance Group",
                  description: "self_link for a managed instance group",
                  type: "string",
                },
                balancing_mode: {
                  title: "Balancing mode",
                  type: ["string", "null"],
                  default: null,
                },
                capacity_scaler: {
                  title: "Capacity scaler",
                  type: ["number", "null"],
                  default: null,
                },
                description: {
                  title: "Description",
                  type: ["string", "null"],
                  default: null,
                },
                max_connections: {
                  title: "Max Connections",
                  type: ["number", "null"],
                  default: null,
                },
                max_connections_per_instance: {
                  title: "Max Connections Per Instance",
                  type: ["number", "null"],
                  default: null,
                },
                max_connections_per_endpoint: {
                  title: "Max Connections Per Endpoint",
                  type: ["number", "null"],
                  default: null,
                },
                max_rate: {
                  title: "Max Rate",
                  type: ["number", "null"],
                  default: null,
                },
                max_rate_per_instance: {
                  title: "Max Rate Per Intance",
                  type: ["number", "null"],
                  default: null,
                },
                max_rate_per_endpoint: {
                  title: "Max Rage Per Endpoint",
                  type: ["number", "null"],
                  default: null,
                },
                max_utilization: {
                  title: "Max Utilization",
                  type: ["number", "null"],
                  default: null,
                },
              },
              required: ["group"],
            },
          },
          iap_config: {
            type: "object",
            title: "IAP Config",
            properties: {
              enable: {
                type: "boolean",
                default: false,
              },
              oauth2_client_id: {
                title: "Oauth2 Client ID",
                type: "string",
                default: "",
              },
              oauth2_client_secret: {
                title: "Oauth2 Client Secret",
                type: "string",
                default: "",
              },
            },
          },
        },
        required: ["name", "protocol", "port_name", "port", "health_check"],
      },
    },
    create_url_map: {
      type: "boolean",
      default: true,
      description: "Set to `false` if url_map variable is provided",
      title: "Create URL Map",
    },
    url_map: {
      description:
        "The url_map resource to use. Default is to send all traffic to first backend.",
      type: ["string", "null"],
      title: "URL Map",
      default: null,
    },
    http_forward: {
      type: "boolean",
      default: true,
      title: "HTTP Forward",
      description: "Set to `false` to disable HTTP port 80 forward",
    },
    ssl: {
      type: "boolean",
      description:
        "Set to `true` to enable SSL support, requires variable `ssl_certificates` - a list of self_link certs",
      default: false,
    },
    ssl_policy: {
      description: "Selfink to SSL Policy",
      type: ["string", "null"],
      default: null,
      title: "SSL Policy",
    },
    quic: {
      type: "boolean",
      description: "Set to `true` to enable QUIC support",
      default: false,
      title: "QUIC",
    },
    use_ssl_certificates: {
      type: "boolean",
      description:
        "If true, use the certificates provided by `ssl_certificates`, otherwise, create cert from `private_key` and `certificate`",
      default: false,
      title: "Use SSL Certificates",
    },
    ssl_certificates: {
      title: "SSL Certificates:",
      type: "array",
      description: "SSL cert self_link list. Required if `ssl` is `true`",
      items: {
        type: "string",
      },
      default: [],
    },
    security_policy: {
      title: "Security Policy",
      description:
        "The resource URL for the security policy to associate with the backend service",
      type: ["string", "null"],
      default: null,
    },
    cdn: {
      title: "CDN",
      description: "Set to `true` to enable cdn on backend.",
      type: "boolean",
      default: false,
    },
    https_redirect: {
      type: "boolean",
      description: "Set to `true` to enable https redirect on the lb.",
      title: "HTTP Redirect",
      default: false,
    },
  },
  required: ["name"],
};
