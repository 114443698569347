import { db } from "../firebase";

export const fetchOrganization = async (uid) => {
  let orgRefDoc = await db.collection("organizations").doc(uid).get();
  let org = { ...orgRefDoc.data(), id: orgRefDoc.id };
  //console.log(org);
  return org;
};

export const fetchOrganizations = async (uid) => {
  const userDoc = await db.collection("users").doc(uid).get();
  let orgs = [];
  for (let oid of userDoc.data().organizations) {
    let orgRef = await db.collection("organizations").doc(oid).get();
    let org = orgRef.data();
    org.id = orgRef.id;
    orgs.push(org);
  }
  return orgs;
};

export const fetchEnvironment = async (orgId, uid) => {
  let envDocRef = await db
    .collection(`organizations/${orgId}/environments`)
    .doc(uid)
    .get();
  //console.log(envDocRef);
  let envData = envDocRef.data();
  return { ...envData, id: envDocRef.id };
};

export const fetchEnvironments = async (orgId) => {
  //console.log("fetchEnvironments");
  let environmentsRefDocs = await db
    .collection(`organizations/${orgId}/environments`)
    .get();
  let environments = [];
  environmentsRefDocs.forEach((doc) => {
    let env = doc.data();
    environments.push({ ...env, id: doc.id });
  });

  return environments;
};

export const addEnvironment = async (orgId, env) => {
  const envDoc = await db
    .collection(`organizations/${orgId}/environments`)
    .add(env);
  return envDoc;
};

export const removeEnvironment = async (orgID, envId) => {
  const res = await db
    .collection(`organizations/${orgID}/environments`)
    .doc(envId)
    .delete();
  return res;
};

export const updateOrganizationName = async (orgId, name) => {
  let orgRefDoc = db.collection("organizations").doc(orgId);
  let res = await orgRefDoc.set({ name: name }, { merge: true });
  return res;
};
