import { React, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ReplayIcon from "@material-ui/icons/Replay";
import ClearIcon from "@material-ui/icons/Clear";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import PlayArrow from "@material-ui/icons/PlayArrow";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { gcpResourceConfigs, getType } from "./gcpResourceDefintions";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "fit-content",
    //height: 10,
  },
  resourceIcon: {
    margin: 5,
    height: 25,
    width: 25,
  },
  button: {
    //margin: theme.spacing(1),
    width: 10,
    heigth: 10,
    margin: 1,
  },
  buttonGroup: {
    //margin: theme.spacing(1),
    display: "flex",
    margin: 1,
    alignItems: "center",
  },
}));

function GCPResourceButtonGroup({
  node,
  refreshNodes,
  sendNodeUpstream,
  removeNode,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    console.log("CLICKED ADD");
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePlan = (event) => {
    alert("Handle Plan");
  };
  const handleApply = (event) => {
    alert("Handle Apply");
  };
  const handleDestroy = (event) => {
    alert("Handle Destroy");
  };
  const handleRemove = (event) => {
    console.log("CLICKED REMOVE");
    removeNode(node);
  };
  const allowedToAdd = (item) => {
    // This function checks if there is already one of the onlyOneAllowedType in node.children
    const onlyOneAllowedType = [
      "orgIam",
      "folderIam",
      "projectIam",
      "serviceAccounts",
      "firewalls",
      "publicIPs",
    ];
    let itemType = getType(item);
    if (!onlyOneAllowedType.includes(itemType)) {
      return true;
    }
    for (let child of node.children) {
      if (child.type === itemType) {
        return false;
      }
    }
    return true;
  };
  const handleAddResource = (e) => {
    console.log("addResource");
    e.preventDefault();
    const { itemValue } = e.currentTarget.dataset;
    console.log(`itemValue=${itemValue} getType=${getType(itemValue)}`);
    if (!itemValue) {
      setAnchorEl(null);
      return;
    }
    if (allowedToAdd(itemValue)) {
      const id = uuidv4();
      node.children.push({
        type: getType(itemValue),
        parent: {
          type: node.type,
          id: node.id,
        },
        id: id,
        key: id,
        status: "UNINITIALIZED",
        children: [],
      });
      //sort children
      node.children.sort((a, b) => {
        return (
          gcpResourceConfigs[a.type].priority -
          gcpResourceConfigs[b.type].priority
        );
      });
      console.log(node);
      refreshNodes();
    } else {
      alert(`You can specify only one ${itemValue} in the parent`);
    }
    setAnchorEl(null);
  };

  const sendCurrentNodeUpstream = (event) => {
    event.preventDefault();
    event.stopPropagation();
    console.log(`send upstream node=${node.type}`);
    sendNodeUpstream(node);
  };
  return (
    <div className={classes.buttonGroup}>
      <Tooltip title="Module Config">
        <IconButton
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={sendCurrentNodeUpstream}
        >
          <MoreHoriz />
        </IconButton>
      </Tooltip>
      {node.type !== "organization" && (
        <Tooltip title="Plan">
          <IconButton
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handlePlan}
          >
            <ReplayIcon />
          </IconButton>
        </Tooltip>
      )}
      {node.type !== "organization" && (
        <Tooltip title="Apply">
          <IconButton
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleApply}
          >
            <PlayArrow />
          </IconButton>
        </Tooltip>
      )}
      {node.type !== "organization" && (
        <Tooltip title="Destroy">
          <IconButton
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleDestroy}
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
      )}
      {gcpResourceConfigs[node.type].allowedChildren.length !== 0 && (
        <Tooltip title="Add Resource">
          <IconButton
            variant="contained"
            color="primary"
            aria-controls="long-menu"
            aria-haspopup="true"
            className={classes.button}
            onClick={openMenu}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}
      {node.children.length === 0 && node.type !== "organization" && (
        <Tooltip title="Remove Resource">
          <IconButton
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleRemove}
          >
            <RemoveIcon />
          </IconButton>
        </Tooltip>
      )}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {gcpResourceConfigs[node.type].allowedChildren.map((option) => (
          <MenuItem
            key={option}
            onClick={handleAddResource}
            data-item-value={option}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
export default GCPResourceButtonGroup;
