export const organizationSchema = {
  type: "object",
  properties: {
    org_id: {
      type: "string",
      description: "Organization ID",
    },
    billing_account: {
      type: "string",
      description: "Billing Account ID",
    },
  },
};
