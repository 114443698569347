export const natSchema = {
  title: "NAT Router",
  type: "object",
  properties: {
    name: {
      title: "NAT Router name",
      type: "string",
    },
    nats: {
      title: "NATs to deploy on this router.",
      type: "array",
      items: {
        properties: {
          name: {
            title: "Name of the NAT",
            type: "string",
          },
          source_subnetwork_ip_ranges_to_nat: {
            title: "How NAT should be configured per Subnetwork",
            type: "string",
            enum: [
              "ALL_SUBNETWORKS_ALL_IP_RANGES",
              "ALL_SUBNETWORKS_ALL_PRIMARY_IP_RANGES",
              "LIST_OF_SUBNETWORKS",
            ],
            default: "ALL_SUBNETWORKS_ALL_IP_RANGES",
          },
          nat_ip_allocate_option: {
            title: "IP Allocation Option",
            description: "How external IPs should be allocated for this NAT",
            type: "string",
            enum: ["MANUAL_ONLY", "AUTO_ONLY"],
            default: "AUTO_ONLY",
          },
          nat_ips: {
            title: "Self-links of NAT IPs",
            description: "List of reserved NAT IPs self_links",
            type: "array",
            items: { type: "string" },
          },
          subnetwork: {
            title: "List of subnetworks to be NATed",
            type: "array",
            items: {
              properties: {
                name: {
                  type: "string",
                  title: "Subnet name",
                },
                region: {
                  type: "string",
                  title: "Subnet region",
                },
              },
              required: ["name", "region"],
            },
          },
        },
      },
    },
  },
};
