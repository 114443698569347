export const gcsSchema = {
  title: "GCS Bucket",
  type: "object",
  properties: {
    name: {
      title: "Bucket name",
      type: "string",
    },
    location: {
      title: "Bucket Location",
      type: "string",
    },
    prefix: {
      title: "Prefix used to generate the bucket name.",
      type: "string",
    },
    versioning: {
      title: "Enable versioning",
      type: "boolean",
      default: true,
    },
    force_destroy: {
      title: "Force destroy",
      description: "Force bucket destruction on terraform destroy",
      type: "boolean",
      default: false,
    },
    storage_class: {
      title: "Storage class",
      type: "string",
      enum: [
        "STANDARD",
        "MULTI_REGIONAL",
        "REGIONAL",
        "NEARLINE",
        "COLDLINE",
        "ARCHIVE",
      ],
      default: "STANDARD",
    },
    admins: {
      title: "List of bucket admins",
      type: "array",
      items: {
        description:
          "user:foo@example.com or grop:bar@example.com or serviceAccount:sa-id@projectid.gserviceaccounts.com",
        type: "string",
      },
    },
    viewers: {
      title: "List of bucket viewers",
      type: "array",
      items: {
        description:
          "user:foo@example.com or grop:bar@example.com or serviceAccount:sa-id@projectid.gserviceaccounts.com",
        type: "string",
      },
    },
    creators: {
      title: "List of bucket object creators",
      type: "array",
      items: {
        description:
          "user:foo@example.com or grop:bar@example.com or serviceAccount:sa-id@projectid.gserviceaccounts.com",
        type: "string",
      },
    },
    hmac_key_admins: {
      title: "List of HMAC Key Admins",
      description:
        "IAM-style members who will be granted roles/storage.hmacKeyAdmin on all buckets.",
      type: "array",
      items: {
        description:
          "user:foo@example.com or grop:bar@example.com or serviceAccount:sa-id@projectid.gserviceaccounts.com",
        type: "string",
      },
    },
    labels: {
      type: "array",
      title: "Map of labels for project",
      items: {
        type: "object",
        properties: {
          key: {
            type: "string",
          },
          value: {
            type: "string",
          },
        },
      },
    },
  },
  required: ["name", "location"],
};
