import { React, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { useAuth } from "../contexts/AuthContext";
import {
  fetchOrganizations,
  fetchOrganization,
  fetchEnvironments,
  addEnvironment,
  removeEnvironment,
  updateOrganizationName,
} from "../utils/db";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    //width: "fit-content",
    width: 500,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export function Organization({ organizationId }) {
  const [environments, setEnvironments] = useState([]);
  const [orgName, setOrgName] = useState("");
  const [orgNameInput, setOrgNameInput] = useState("");
  const [environmentName, setEnvironmentName] = useState("");
  const [openOrgName, setOpenOrgName] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const getOrganization = async () => {
      const org = await fetchOrganization(organizationId);
      setOrgName(org.name);
      setOrgNameInput(org.name);
    };
    const getEnvironments = async () => {
      const envs = await fetchEnvironments(organizationId);
      setEnvironments(envs);
    };
    getEnvironments();
    getOrganization();
    // eslint-disable-next-line
  }, []);

  const handleCloseOrgName = () => {
    setOpenOrgName(false);
  };
  const handleOpenOrgName = () => {
    setOpenOrgName(true);
  };

  const addEnv = async (e) => {
    e.preventDefault();
    if (!environmentName) {
      alert("The environment can not be an empty sting");
      return;
    }
    for (const env of environments) {
      if (env.name === environmentName) {
        alert("Can not add the same environment");
        return;
      }
    }
    let env = {
      name: environmentName,
      bucket: "",
      active: false,
      architecture: {
        type: "organization",
        id: 1,
        children: [],
        properties: { org_id: "", billing_account: "" },
      },
    };
    try {
      await addEnvironment(organizationId, env);
    } catch (err) {
      alert(`Failed to add the environment. Error ${JSON.stringify(err)}`);
      return;
    }
    setEnvironmentName("");
    let newEnvs = await fetchEnvironments(organizationId);
    setEnvironments(newEnvs);
  };

  const removeEnv = async (envId) => {
    try {
      console.log(`remove from ${organizationId} environment ${envId}`);
      await removeEnvironment(organizationId, envId);
      let envs = await fetchEnvironments(organizationId);
      setEnvironments(envs);
    } catch (err) {
      alert(JSON.stringify(err));
    }
  };

  const changeName = async () => {
    if (!orgNameInput) {
      alert("Name can't be an empty string");
      return;
    }
    try {
      await updateOrganizationName(organizationId, orgNameInput);
      const org = await fetchOrganization(organizationId);
      setOrgName(org.name);
      setOrgNameInput(org.name);
      setOpenOrgName(false);
    } catch {
      alert("Failed to update the org name");
    }
  };

  return (
    <Paper className={classes.paper}>
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column">
          <Typography component="div">
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box fontSize="h6.fontSize" m={1} width="60%">
                Organization Name:
              </Box>
              <Box m={1} display="flex" justifyContent="flex-end" width="40%">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenOrgName}
                >
                  Change Name
                </Button>
                <Dialog
                  open={openOrgName}
                  onClose={handleCloseOrgName}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Change Organization Name
                  </DialogTitle>
                  <DialogContent>
                    <TextField
                      error={!orgName ? true : false}
                      autoFocus
                      margin="dense"
                      id="orgName"
                      label="Organization Name"
                      defaultValue={orgNameInput}
                      fullWidth
                      onChange={(e) => setOrgNameInput(e.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseOrgName} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={changeName} color="primary">
                      Set Name
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row">
              <Box fontSize="fontSize" m={1}>
                {orgName}
              </Box>
            </Box>
            <Divider component="div" />
            <Box fontSize="h6.fontSize" m={1}>
              Organization Id:
            </Box>
            <Box fontSize="fontSize" m={1}>
              {organizationId}
            </Box>
            <Divider component="div" />
            <Box fontSize="h6.fontSize" m={1}>
              Environments
            </Box>
            <Box display="flex" flexDirection="row" m={1}>
              <form
                onSubmit={addEnv}
                style={{ display: "flex", width: "100%" }}
              >
                <TextField
                  error={!environmentName ? true : false}
                  margin="normal"
                  id="environmentName"
                  label="Environment Name"
                  value={environmentName}
                  fullWidth
                  onChange={(e) => setEnvironmentName(e.target.value)}
                  inputProps={{ pattern: "[-a-zA-Z0-9]{2,15}" }}
                  helperText="must match regex [-a-zA-Z0-9]{1,15}"
                />
                <Button color="primary" startIcon={<AddIcon />} type="submit">
                  Add
                </Button>
              </form>
            </Box>
            <Divider component="div" />
            <Box display="flex" flexDirection="column">
              {environments.map((env) => (
                <Env key={env.id} env={env} removeEnvironment={removeEnv} />
              ))}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}

export function Env({ env, removeEnvironment }) {
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row">
        <Box
          fontSize="fontSize"
          m={1}
          alignItems="center"
          display="flex"
          width="60%"
        >
          {env.name}
        </Box>
        <Box m={1} display="flex" justifyContent="flex-end" width="40%">
          <IconButton
            onClick={() => removeEnvironment(env.id)}
            m={1}
            disabled={env.active}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider component="div" />
    </Box>
  );
}

export default function Organizations() {
  const [organizations, setOrganizations] = useState([]);
  const { currentUser } = useAuth();
  useEffect(() => {
    const getOrganizations = async () => {
      const orgs = await fetchOrganizations(currentUser.uid);
      setOrganizations(orgs);
    };
    getOrganizations();
    // eslint-disable-next-line
  }, []);

  return (
    <Box display="flex" flexDirection="column" width="auto">
      {organizations.map((org) => (
        <Box display="flex" flexDirection="column" m={1} key={org.id}>
          <Organization organizationId={org.id} key={org.id} />
        </Box>
      ))}
      <Divider component="div" />
    </Box>
  );
}
