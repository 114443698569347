export const folderIAMSchema = {
  title: "Define permissions for the folder",
  type: "object",
  properties: {
    permissions: {
      type: "array",
      title: "List of Permissions",
      items: {
        properties: {
          role: {
            title: "Role",
            type: "string",
            examples: ["roles/editor", "roles/owner"],
          },
          personas: {
            type: "array",
            title: "List of personas to which the role will be granted",
            items: {
              properties: {
                persona: {
                  title: "Persona",
                  type: "string",
                  examples: [
                    "user:@user1@my-company.com",
                    "serviceAccount:sa-id@projectid.gserviceaccounts.com",
                    "group:group@my-company.com",
                  ],
                },
              },
            },
          },
        },
      },
    },
  },
};
