export const gkeSchema = {
  title: "GKE Config",
  type: "object",
  properties: {
    name: {
      title: "GKE Name",
      type: "string",
    },
    description: {
      title: "Description",
      type: "string",
    },
    regional: {
      title: "Regional",
      type: "boolean",
      description:
        "Whether is a regional cluster (zonal cluster if set false. WARNING: changing this after cluster creation is destructive!)",
      default: true,
    },
    region: {
      description:
        "The region to host the cluster in (optional if zonal cluster / required if regional)",
      type: ["string", "null"],
      default: null,
      title: "Region",
    },
    zones: {
      title: "Zones",
      description:
        "The zones to host the cluster in (optional if regional cluster / required if zonal)",
      type: "array",
      items: {
        type: "string",
      },
      default: [],
    },
    network: {
      type: "string",
      description: "The VPC network to host the cluster in (required)",
    },
    network_project_id: {
      title: "Network Project ID",
      description:
        "The project ID of the shared VPC's host (for shared vpc support)",
      type: "string",
      default: "",
    },
    subnetwork: {
      description: "The subnetwork to host the cluster in (required)",
      type: "string",
    },
    ip_range_pods: {
      title: "Pods IP Range",
      description:
        "The _name_ of the secondary subnet ip range to use for pods",
      type: "string",
    },
    ip_range_services: {
      title: "Services IP Range",
      description:
        "The _name_ of the secondary subnet range to use for services",
      type: "string",
    },
    enable_private_nodes: {
      title: "Enable Private Nodes",
      type: "boolean",
      default: true,
    },
    master_ipv4_cidr_block: {
      title: "Mater IPv4 CIDR Block",
      description:
        "The IP range in CIDR notation to use for the hosted master network",
      default: "10.0.0.0/28",
      type: "string",
    },
    kubernetes_version: {
      type: "string",
      title: "Kubernetes version",
      default: "latest",
      description:
        "The Kubernetes version of the masters. If set to 'latest' it will pull latest available version in the selected region.",
    },
    master_authorized_networks: {
      title: "Master Authorized Networks",
      type: "array",
      description:
        "List of master authorized networks. If none are provided, disallow external access (except the cluster node IPs, which GKE automatically whitelists).",
      items: {
        type: "object",
        properties: {
          cidr_block: {
            type: "string",
            title: "CIDR Block",
          },
          display_name: {
            type: "string",
            title: "Display Name",
          },
        },
        required: ["cidr_block", "display_name"],
      },
      default: [],
    },
    enable_vertical_pod_autoscaling: {
      description:
        "Vertical Pod Autoscaling automatically adjusts the resources of pods controlled by it",
      type: "boolean",
      title: "Enable Vertical Pod Autoscaling",
      default: false,
    },
    horizontal_pod_autoscaling: {
      description: "Enable horizontal pod autoscaling addon",
      type: "boolean",
      title: "Horizontal Pod Autoscaling",
      default: true,
    },
    http_load_balancing: {
      type: "boolean",
      title: "HTTP Load Balancing",
      description: "Enable httpload balancer addon",
      default: true,
    },
    network_policy: {
      title: "Network Policy",
      type: "boolean",
      default: true,
      description: "Enable network policy addon",
    },
    network_policy_provider: {
      title: "Network Policy Provider",
      type: "string",
      default: "CALICO",
      description: "The network policy provider.",
    },
    maintenance_start_time: {
      title: "Maintenance Start Time",
      type: "string",
      default: "05:00",
      description:
        "Time window specified for daily or recurring maintenance operations in RFC3339 format",
    },
    maintenance_exclusions: {
      title: "Maintenance Exclusions",
      description:
        "List of maintenance exclusions. A cluster can have up to three",
      type: "array",
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
          },
          start_time: {
            type: "string",
          },
          end_time: {
            type: "string",
          },
        },
        required: ["name", "start_time", "end_time"],
      },
      default: [],
    },
    initial_node_count: {
      title: "Initial Node Count",
      description:
        "The number of nodes to create in this cluster's default node pool.",
      default: 0,
      type: "number",
    },
    remove_default_node_pool: {
      title: "Remove default node pool",
      type: "boolean",
      default: true,
      description: "Remove default node pool while setting up the cluster",
    },
    node_pools: {
      title: "Node Pools",
      type: "array",
      description: "List of node pools",
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
          },
          service_account: {
            type: "string",
            title: "Service Account",
          },
          disk_size_gb: {
            type: "number",
            title: "Disk Size in GB",
            default: 50,
          },
          disk_type: {
            type: "string",
            title: "Disk Type",
            default: "pd-standard",
            enum: ["pd-standard", "pd-balanced", "pd-ssd"],
          },
          guest_accelerator: {
            type: "array",
            default: [],
            title: "Guest Accelarator",
            items: {
              type: "object",
              properties: {
                type: {
                  type: "string",
                  default: "nvidia-tesla-k80",
                },
                count: {
                  type: "number",
                  default: 1,
                },
              },
              required: ["type", "count"],
            },
          },
          image_type: {
            type: "string",
            title: "Image Type",
            default: "COS",
          },
          machine_type: {
            type: "string",
            title: "Machine Type",
            default: "n2-standard-2",
          },
          local_ssd_cound: {
            type: "number",
            title: "Local SSD Count",
            default: 0,
          },
          preemptible: {
            type: "boolean",
            default: false,
            description: "Use preamtable machines",
          },
          enable_secure_boot: {
            type: "boolean",
            title: "Enable Secure Boot",
            default: false,
          },
          enable_integrity_monitoring: {
            type: "boolean",
            title: "Enable Integrity Monitoring",
            default: true,
          },
          oauth_scopes: {
            type: "array",
            title: "OAuth scopes",
            items: {
              type: "string",
            },
            default: ["cloud-platform"],
          },
          labels: {
            default: [],
            type: "array",
            title: "Labels",
            items: {
              type: "object",
              properties: {
                key: {
                  type: "string",
                },
                value: {
                  type: "string",
                },
              },
              required: ["key", "value"],
            },
          },
          metadata: {
            default: [],
            type: "array",
            title: "Metadata",
            items: {
              type: "object",
              properties: {
                key: {
                  type: "string",
                },
                value: {
                  type: "string",
                },
              },
              required: ["key", "value"],
            },
          },
          tags: {
            type: "array",
            default: [],
            title: "Network tags",
            items: {
              type: "string",
            },
          },
          taints: {
            title: "Kubernetes Taints",
            type: "array",
            items: {
              type: "object",
              properties: {
                key: {
                  type: "string",
                },
                value: {
                  type: "string",
                },
                effect: {
                  type: "string",
                  enum: ["NO_SCHEDULE", "PREFER_NO_SCHEDULE", "NO_EXECUTE"],
                  default: "NO_SCHEDULE",
                },
              },
              required: ["key", "value", "effect"],
              default: [],
            },
          },
        },
        required: ["name"],
      },
    },
    resource_usage_export_dataset_id: {
      description:
        "The ID of a BigQuery Dataset for using BigQuery as the destination of resource usage export.",
      title: "Resouce Usage Export Dataset ID",
      type: "string",
      default: "",
    },
    enable_network_egress_export: {
      type: "boolean",
      default: false,
      title: "Enable Network Egress Export",
      description:
        "Whether to enable network egress metering for this cluster. If enabled, a daemonset will be created in the cluster to meter network egress traffic.",
    },
    enable_resource_consumption_export: {
      title: "Enable Resource Consumption Export",
      type: "boolean",
      default: false,
      description:
        "Whether to enable resource consumption metering on this cluster. When enabled, a table will be created in the resource export BigQuery dataset to store resource consumption data. The resulting table can be joined with the resource usage table or with BigQuery billing export.",
    },
    cluster_autoscaling: {
      title: "Cluster Autoscaling",
      description:
        "Cluster autoscaling configuration. See [more details](https://cloud.google.com/kubernetes-engine/docs/reference/rest/v1beta1/projects.locations.clusters#clusterautoscaling)",
      type: "object",
      default: {},
      properties: {
        enabled: {
          type: "boolean",
          default: false,
        },
        max_cpu_cores: {
          type: "number",
          default: 0,
        },
        min_cpu_cores: {
          type: "number",
          default: 0,
        },
        max_memory_gb: {
          type: "number",
          default: 0,
        },
        min_memory_gb: {
          type: "number",
          default: 0,
        },
      },
    },
    upstream_nameservers: {
      description:
        "If specified, the values replace the nameservers taken by default from the node’s /etc/resolv.conf",
      title: "Upstream Nameservers",
      type: "array",
      default: [],
      items: {
        type: "string",
      },
    },
    configure_ip_masq: {
      type: "boolean",
      title: "Configure IP Masq",
      description:
        "Enables the installation of ip masquerading, which is usually no longer required when using aliasied IP addresses. IP masquerading uses a kubectl call, so when you have a private cluster, you will need access to the API server.",
      default: false,
    },
    ip_masq_link_local: {
      title: "IP Masq link local",
      description:
        "Whether to masquerade traffic to the link-local prefix (169.254.0.0/16).",
      type: "boolean",
      default: false,
    },
    non_masquerade_cidrs: {
      type: "array",
      description:
        "List of strings in CIDR notation that specify the IP address ranges that do not use IP masquerading.",
      default: ["10.0.0.0/8", "172.16.0.0/12", "192.168.0.0/16"],
      title: "Non Masquerade CIDRS",
      items: {
        type: "string",
      },
    },
    logging_service: {
      type: "string",
      title: "Logging Service",
      description: "The logging service that the cluster should write logs to",
      enum: [
        "logging.googleapis.com",
        "logging.googleapis.com/kubernetes",
        "none",
      ],
      default: "logging.googleapis.com/kubernetes",
    },
    monitoring_service: {
      type: "string",
      title: "Monitoring Service",
      description:
        "The monitoring service that the cluster should write metrics to. Automatically send metrics from pods in the cluster to the Google Cloud Monitoring API. VM metrics will be collected by Google Compute Engine regardless of this setting",
      enum: [
        "monitoring.googleapis.com",
        "monitoring.googleapis.com/kubernetes",
        "none",
      ],
      default: "monitoring.googleapis.com/kubernetes",
    },
    create_service_account: {
      type: "boolean",
      description:
        "Defines if service account specified to run nodes should be created.",
      default: false,
      title: "Create Service Account",
    },
    service_account: {
      description:
        "The service account to run nodes as if not overridden in `node_pools`. The create_service_account variable default value (true) will cause a cluster-specific service account to be created.",
      title: "Service Account",
      default: "",
      type: "string",
    },
    cluster_resource_labels: {
      description:
        "The GCE resource labels (a map of key/value pairs) to be applied to the cluster",
      title: "Cluster Resource Labels",
      default: [],
      type: "array",
      items: {
        type: "object",
        properties: {
          key: {
            type: "string",
          },
          value: {
            type: "string",
          },
        },
        required: ["key", "value"],
      },
    },
    default_max_pods_per_node: {
      description: "The maximum number of pods to schedule per node",
      default: 110,
      type: "number",
      title: "Default Max Pods Per Node",
    },
    node_metadata: {
      title: "Node Metadata",
      default: "GKE_METADATA_SERVER",
      type: "string",
      description:
        "Specifies how node metadata is exposed to the workload running on the node",
    },
    database_encryption: {
      title: "Database Encryption",
      description:
        'Application-layer Secrets Encryption settings. The object format is {state = string, key_name = string}. Valid values of state are: "ENCRYPTED"; "DECRYPTED". key_name is the name of a CloudKMS key.',
      type: "array",
      items: {
        type: "object",
        properties: {
          state: {
            type: "string",
            enum: ["ENCRYPTED", "DECRYPTED"],
            default: "DECRYPTED",
          },
          key_name: {
            type: "string",
            default: "",
          },
        },
      },
      default: [{ state: "DECRYPTED", key_name: "" }],
    },
    identity_namespace: {
      description:
        "Workload Identity namespace. (Default value of `enabled` automatically sets project based namespace `[project_id].svc.id.goog`)",
      type: "boolean",
      title: "Identity Namespace",
      default: true,
    },
    release_channel: {
      title: "Release Channel",
      description:
        "The release channel of this cluster. Accepted values are `UNSPECIFIED`, `RAPID`, `REGULAR` and `STABLE`. Defaults to `UNSPECIFIED`.",
      enum: ["UNSPECIFIED", "RAPID", "REGULAR", "STABLE"],
      default: "UNSPECIFIED",
    },
    enable_shielded_nodes: {
      title: "Enable Shielded Nodes",
      type: "boolean",
      default: true,
      description:
        "Enable Shielded Nodes features on all nodes in this cluster",
    },
  },

  required: [
    "name",
    "network",
    "subnetwork",
    "ip_range_pods",
    "ip_range_services",
  ],
};
