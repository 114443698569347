import SignInSide from "./components/SigninSide";
import Dashboard from "./components/Dashboard";
import PrivateRoute from "./components/PrivateRoute";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={SignInSide} />
        <Route path="/login" component={SignInSide} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/dashboard/organizations" component={Dashboard} />
        <PrivateRoute path="/dashboard/environments" component={Dashboard} />
      </Switch>
    </Router>
  );
}

export default App;
