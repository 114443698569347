import { React } from "react";
import { Card, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { gcpResourceConfigs } from "./gcpResourceDefintions";
import GCPResourceButtonGroup from "./GCPResourceButtonGroup";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "fit-content",
    //height: 10,
  },
  resourceIcon: {
    display: "flex",
    margin: 5,
    height: 35,
    //width: "fit-content",
  },
  details: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  content: {
    display: "flex",
    margin: 1,
    flexDirection: "row",
    padding: 5,
    alignItems: "center",
  },
}));

function GCPResource({ node, refreshNodes, sendNodeUpstream, removeNode }) {
  const classes = useStyles();
  const getName = (node) => {
    if ("displayField" in gcpResourceConfigs[node.type]) {
      let field = gcpResourceConfigs[node.type].displayField;
      if ("properties" in node) {
        if (field in node.properties) {
          return node.properties[field];
        }
      }
    }
    return "";
  };
  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <img
          src={gcpResourceConfigs[node.type].icon}
          alt="resource icon"
          className={classes.resourceIcon}
          data-allow-toggle
        ></img>
      </div>
      <div className={classes.content}>
        <Typography variant="subtitle1">
          {gcpResourceConfigs[node.type].displayName} {getName(node)}
        </Typography>
      </div>
      <GCPResourceButtonGroup
        node={node}
        refreshNodes={refreshNodes}
        sendNodeUpstream={sendNodeUpstream}
        removeNode={removeNode}
      />
    </Card>
  );
}

export default GCPResource;
