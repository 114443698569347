import { React, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Environment from "./Environment";
import { fetchEnvironments, fetchOrganizations } from "../utils/db";
import { useAuth } from "../contexts/AuthContext";

function TabPanel(props) {
  // This code was taken from the material-ui examples website
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box m={1}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Environments() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [organizations, setOrganizations] = useState([]);
  const [org, setOrg] = useState(null); // This holds currently selected organization id
  const [environments, setEnvironments] = useState([]);
  const { currentUser } = useAuth();

  /*
   * This handles change in tab selection
   */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const organizationChange = async (org) => {
    setOrg(org);
    await getEnvironmentNamesForOrg(org);
  };

  useEffect(() => {
    getOrganizations();
    getEnvironmentNamesForOrg(org);
    // eslint-disable-next-line
  }, []);

  const getEnvironmentNamesForOrg = async (orgId) => {
    if (!orgId) {
      return;
    }
    const envs = await fetchEnvironments(orgId);
    let reduce_envs = envs.map((env) => ({ name: env.name, id: env.id }));
    setEnvironments(reduce_envs);
  };

  const getOrganizations = async () => {
    let orgs = await fetchOrganizations(currentUser.uid);
    setOrganizations(orgs);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="default"
        style={{ display: "flex", direction: "row" }}
      >
        <Box display="flex" flexDirection="row">
          <Box>
            <OrganizationsList
              organizations={organizations}
              organizationChange={organizationChange}
            />
          </Box>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
          >
            {environments.map((env, index) => (
              <Tab
                key={index}
                label={env.name}
                id={`scrollable-auto-tab-${index}`}
              />
            ))}
          </Tabs>
        </Box>
      </AppBar>
      {environments.map((env, index) => (
        <TabPanel value={value} index={index} key={index}>
          <Environment environmentId={env.id} organizationId={org} />
        </TabPanel>
      ))}
    </div>
  );
}

const useStylesForOrgList = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export function OrganizationsList({ organizations, organizationChange }) {
  const classes = useStylesForOrgList();
  const [state, setState] = useState("");

  const handleChange = (event) => {
    setState(event.target.value);
    organizationChange(event.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">Organization</InputLabel>
      <Select value={state} onChange={handleChange}>
        {organizations.map((org, index) => (
          <option key={index} value={org.id}>
            {org.name}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}
