import React from "react";
import { useState, useEffect } from "react";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import GCPResource from "./GCPResource";

import Form from "@rjsf/material-ui";
import { gcpResourceConfigs } from "./gcpResourceDefintions";
import {
  Button,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  DialogContentText,
} from "@material-ui/core";
import { fetchEnvironment } from "../utils/db";
import { db } from "../firebase";

export default function Environment({ environmentId, organizationId }) {
  const [node, setNode] = useState({});
  const [expanded, setExpanded] = useState([]);
  const [openConfigDialog, setOpenConfigDialog] = useState(false);
  const [gcsBucket, setGcsBucket] = useState("");
  const [openGCSStateBucketDialog, setOpenGCSStateBucketDialog] =
    useState(false);
  const [environment, setEnvironment] = useState({
    name: "",
    architecture: {},
    id: null,
  });
  useEffect(() => {
    getEnvironment();
    // eslint-disable-next-line
  }, []);

  const handleCloseConfigDialog = () => {
    setOpenConfigDialog(false);
  };

  const handleOpenConfigDialog = () => {
    setOpenConfigDialog(true);
  };

  const handleCloseGCSStateDialog = () => {
    setOpenGCSStateBucketDialog(false);
  };

  const handleOpenGCSStateDialog = () => {
    setOpenGCSStateBucketDialog(true);
  };

  const refreshNodes = async () => {
    let envDocRef = db
      .collection(`organizations/${organizationId}/environments`)
      .doc(environmentId);
    await envDocRef.set(
      { architecture: environment.architecture },
      { merge: true }
    );
    let updatedDocumentRef = await envDocRef.get();
    let data = updatedDocumentRef.data();
    setEnvironment({ ...data, id: envDocRef.id });
  };

  const getEnvironment = async () => {
    let env = await fetchEnvironment(organizationId, environmentId);
    setEnvironment(env);
    setGcsBucket(env.bucket);
  };

  const handleToggle = (event, nodeIds) => {
    event.preventDefault();
    // This code allows the tree to be expanded only when icon or name of the GCPResource are clicked
    if (event.target.getAttribute("data-allow-toggle")) {
      setExpanded(nodeIds);
    }
  };

  const onSubmit = ({ formData }, e) => {
    //console.log(formData);
    node.properties = formData;
    refreshNodes();
    handleCloseConfigDialog();
  };

  const sendNodeUpstream = (node) => {
    //console.log(`node=${node}`);
    setNode(node);
    handleOpenConfigDialog(true);
  };

  function findNode(id, currentNode) {
    if (id === currentNode.id) {
      return currentNode;
    } else {
      // Use a for loop instead of forEach to avoid nested functions
      // Otherwise "return" will not work properly
      for (let i = 0; i < currentNode.children.length; i += 1) {
        let currentChild = currentNode.children[i];

        // Search in the current child
        let result = findNode(id, currentChild);

        // Return the result if the node has been found
        if (result !== false) {
          return result;
        }
      }

      // The node has not been found and we have no more options
      return false;
    }
  }

  const removeNode = (nodeToRemove) => {
    if (
      nodeToRemove.children.length === 0 &&
      ["UNINITIALIZED", "DESTROYED"].includes(nodeToRemove.status)
    ) {
      let architecture = environment.architecture;
      let n = findNode(nodeToRemove.parent.id, architecture);
      n.children = n.children.filter((item) => item.id !== nodeToRemove.id);
      refreshNodes();
      //console.log(n);
    } else {
      alert(`Node has children or the module is not destroyed.`);
    }
  };

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id.toString()}
      label={
        <GCPResource
          node={nodes}
          refreshNodes={refreshNodes}
          sendNodeUpstream={sendNodeUpstream}
          removeNode={removeNode}
        />
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  const displayArchtecture = () => {
    if (!environment) {
      return <p>Not found</p>;
    }
    let r =
      Object.keys(environment.architecture).length === 0 ? (
        <p>Not found</p>
      ) : (
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon data-allow-toggle />}
          defaultExpandIcon={<ChevronRightIcon data-allow-toggle />}
          expanded={expanded}
          onNodeToggle={handleToggle}
        >
          {renderTree(environment.architecture)}
        </TreeView>
      );
    return r;
  };

  const changeStateBucket = async () => {
    let e = await fetchEnvironment(organizationId, environmentId);
    if (e.active) {
      alert("Environment already became active");
      setEnvironment({ ...environment, active: e.active });
    }
    setGcsBucket(gcsBucket);
    console.log(gcsBucket);
    let environmentDocRef = db
      .collection(`organizations/${organizationId}/environments`)
      .doc(environmentId);
    await environmentDocRef.set({ bucket: gcsBucket }, { merge: true });

    let env = await fetchEnvironment(organizationId, environmentId);
    setEnvironment(env);
    handleCloseGCSStateDialog();
  };
  console.log(environment.architecture);
  return (
    <Box display="flex" flexDirection="column" alignItems="left">
      <Box display="flex" flexDirection="column" m={1}>
        <Box m={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenGCSStateDialog}
          >
            Set Environment Bucket
          </Button>
          <Dialog
            open={openGCSStateBucketDialog}
            onClose={handleCloseGCSStateDialog}
            aria-labelledby="gcs-form-dialog-title"
          >
            <DialogTitle id="gcs-form-dialog-title">
              Terraform State Bucket
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Set GCS bucket that will host terraform state files for this
                environment. You won't be able to deploy any module untill this
                is set. Once you run at least one plan of one of the modules in
                the tree you will not be able to change the bucket. Don't
                specify "gs://" before the bucket name
              </DialogContentText>
              <TextField
                error={!gcsBucket ? true : false}
                autoFocus
                disabled={environment.active}
                margin="dense"
                id="stateBucketName"
                label="GCS State Bucket"
                defaultValue={gcsBucket}
                fullWidth
                onChange={(e) => setGcsBucket(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseGCSStateDialog} color="primary">
                Cancel
              </Button>
              <Button
                onClick={changeStateBucket}
                color="primary"
                disabled={environment.active}
              >
                Set Bucket
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="baseline" m={1}>
          <Typography component="h3" variant="h6" m={1}>
            GCS Bucket:&nbsp;&nbsp;
          </Typography>
          <Typography component="div" variant="subtitle1" alignItems="baseline">
            {gcsBucket}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" m={1}>
        {displayArchtecture()}
        <Dialog
          open={openConfigDialog}
          onClose={handleCloseConfigDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          //fullWidth={true}
        >
          <DialogActions>
            <ModuleSettings node={node} onSubmit={onSubmit} />
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

export function ModuleSettings({ node, onSubmit }) {
  //console.log(`type=${node.type}`);
  return (
    <Form
      schema={gcpResourceConfigs[node.type].schema}
      formData={node.hasOwnProperty("properties") ? node.properties : {}}
      onSubmit={onSubmit}
      uiSchema={
        "uiSchema" in gcpResourceConfigs[node.type]
          ? gcpResourceConfigs[node.type].uiSchema
          : {}
      }
    >
      {
        <div>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </div>
      }
    </Form>
  );
}
