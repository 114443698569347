import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth } from "../contexts/AuthContext";

const PrivateRoute = ({ component: Component, ...otherProps }) => {
  const { currentUser } = useAuth();

  return (
    <Route
      {...otherProps}
      render={(props) =>
        currentUser ? (
          <Component {...props} />
        ) : (
          <Redirect to={otherProps.redirectTo ? otherProps.redirectTo : "/"} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PrivateRoute;
