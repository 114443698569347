import { orgFolderSchema } from "./gcpResourceSchemas/orgFolderSchema";
import { folderSchema } from "./gcpResourceSchemas/folderSchema";
import { organizationSchema } from "./gcpResourceSchemas/organizationSchema";
import { projectSchema } from "./gcpResourceSchemas/projectSchema";
import { hostProjectSchema } from "./gcpResourceSchemas/hostProjectSchema";
import { serviceProjectSchema } from "./gcpResourceSchemas/serviceProjectSchema";
import { folderIAMSchema } from "./gcpResourceSchemas/folderIAMSchema";
import { projectIAMSchema } from "./gcpResourceSchemas/projectIAMSchema";
import { orgIAMSchema } from "./gcpResourceSchemas/orgIAMSchema";
import { vpcSchema } from "./gcpResourceSchemas/vpcSchema";
import { sharedVpcSchema } from "./gcpResourceSchemas/sharedVpcSchema";
import { firewallsSchema } from "./gcpResourceSchemas/firewallsSchema";
import { natSchema } from "./gcpResourceSchemas/natSchema";
import { gcsSchema } from "./gcpResourceSchemas/gcsSchema";
import { serviceAccountsSchema } from "./gcpResourceSchemas/serviceAccountsSchema";
import { instanceTemplateSchema } from "./gcpResourceSchemas/instanceTemplateSchema";
import { instanceSchema } from "./gcpResourceSchemas/instanceSchema";
import { migSchema } from "./gcpResourceSchemas/migSchema";
import { httpsLBSchema } from "./gcpResourceSchemas/httpsLBSchema";
import { publicIPSchema } from "./gcpResourceSchemas/publicIPSchema";
import { tcpudpLBSchema } from "./gcpResourceSchemas/tcpudpLBSchema";
import { internalTCPUDPLBSchema } from "./gcpResourceSchemas/internalTCPUDPLBSchema";
import { gkeSchema } from "./gcpResourceSchemas/gkeSchema";
import { bqSchema } from "./gcpResourceSchemas/bqSchema";
export const gcpResourceConfigs = {
  orgFolder: {
    allowedChildren: ["Project", "Host Project", "Org IAM", "Folder"],
    icon: "../GCP/folder.svg",
    displayName: "Org Folder",
    displayField: "name", // Which field from node.properties should be displayed
    priority: 10,
    schema: orgFolderSchema,
  },
  folder: {
    allowedChildren: ["Project", "Host Project", "Folder IAM", "Folder"],
    icon: "../GCP/folder.svg",
    displayName: "Folder",
    displayField: "name",
    priority: 10,
    schema: folderSchema,
  },
  organization: {
    allowedChildren: ["Org Folder", "Project", "Host Project", "Org IAM"],
    icon: "../GCP/organization-node.svg",
    displayName: "Org",
    priority: 1,
    schema: organizationSchema,
  },
  project: {
    allowedChildren: [
      "Service Accounts",
      "Project IAM",
      "VPC",
      "GCS Bucket",
      "Instance Template",
      "HTTP(S) LB",
      "Public IPs",
      "External TCP/UDP LB",
      "Internal TCP/UDP LB",
      "GKE",
      "BQ",
    ],
    icon: "../GCP/project.svg",
    displayName: "Project",
    displayField: "name",
    priority: 20,
    schema: projectSchema,
  },
  hostProject: {
    allowedChildren: [
      "Service Accounts",
      "Project IAM",
      "Shared VPC",
      "Service Project",
      "GCS Bucket",
      "Instance Template",
      "HTTP(S) LB",
      "Public IPs",
      "External TCP/UDP LB",
      "Internal TCP/UDP LB",
      "GKE",
      "BQ",
    ],
    icon: "../GCP/project.svg",
    displayName: "Host Project",
    displayField: "name",
    priority: 20,
    schema: hostProjectSchema,
  },
  serviceProject: {
    allowedChildren: [
      "Service Accounts",
      "Project IAM",
      "VPC",
      "GCS Bucket",
      "Instance Template",
      "HTTP(S) LB",
      "Public IPs",
      "External TCP/UDP LB",
      "Internal TCP/UDP LB",
      "GKE",
      "BQ",
    ],
    icon: "../GCP/project.svg",
    displayName: "Service Project",
    displayField: "name",
    priority: 40,
    schema: serviceProjectSchema,
  },
  folderIam: {
    allowedChildren: [],
    icon: "../GCP/iam.svg",
    displayName: "Folder IAM",
    priority: 5,
    schema: folderIAMSchema,
  },
  projectIam: {
    allowedChildren: [],
    icon: "../GCP/iam.svg",
    displayName: "Project IAM",
    priority: 5,
    schema: projectIAMSchema,
  },
  orgIam: {
    allowedChildren: [],
    icon: "../GCP/iam.svg",
    displayName: "Org IAM",
    priority: 5,
    schema: orgIAMSchema,
  },
  vpc: {
    allowedChildren: ["Firewall Rules", "NAT Router"],
    icon: "../GCP/vpc.svg",
    displayName: "VPC",
    displayField: "name",
    priority: 50,
    schema: vpcSchema,
  },
  sharedVpc: {
    allowedChildren: ["Firewall Rules", "NAT Router"],
    icon: "../GCP/shared-vpc.svg",
    displayName: "Shared VPC",
    displayField: "name",
    priority: 40,
    schema: sharedVpcSchema,
  },
  firewalls: {
    allowedChildren: [],
    icon: "../GCP/firewalls.svg",
    displayName: "Firewall Rules",
    priority: 5,
    schema: firewallsSchema,
  },
  nat: {
    allowedChildren: [],
    icon: "../GCP/router.svg",
    displayName: "NAT Router",
    displayField: "name",
    priority: 100,
    schema: natSchema,
  },
  gcs: {
    allowedChildren: [],
    icon: "../../GCP/gcs.svg",
    displayName: "GCS Bucket",
    displayField: "name",
    priority: 100,
    schema: gcsSchema,
  },
  serviceAccounts: {
    allowedChildren: [],
    icon: "../../GCP/service-account.svg",
    displayName: "Service Accounts",
    priority: 4,
    schema: serviceAccountsSchema,
  },
  instanceTemplate: {
    allowedChildren: ["Instance From Template", "MIG"],
    icon: "../../GCP/compute-engine.svg",
    displayName: "Instance Template",
    displayField: "name_prefix",
    schema: instanceTemplateSchema,
    priority: 100,
    uiSchema: {
      startup_script: {
        "ui:widget": "textarea",
      },
    },
  },
  instance: {
    allowedChildren: [],
    icon: "../../GCP/compute-engine.svg",
    displayName: "Instance From Template",
    priority: 110,
    displayField: "hostname",
    schema: instanceSchema,
  },
  mig: {
    allowedChildren: [],
    icon: "../../GCP/compute-engine.svg",
    displayName: "MIG",
    displayField: "mig_name",
    priority: 110,
    schema: migSchema,
  },
  httpsLB: {
    allowedChildren: [],
    displayName: "HTTP(S) LB",
    displayField: "name",
    icon: "../../GCP/lb.svg",
    schema: httpsLBSchema,
    priority: 100,
  },
  publicIPs: {
    allowedChildren: [],
    icon: "../../GCP/public-ip.svg",
    displayName: "Public IPs",
    schema: publicIPSchema,
    priority: 90,
  },
  tcpudpLB: {
    allowedChildren: [],
    icon: "../../GCP/lb.svg",
    displayName: "External TCP/UDP LB",
    displayField: "name",
    schema: tcpudpLBSchema,
    priority: 100,
  },
  internalTCPUDPLB: {
    allowedChildren: [],
    displayName: "Internal TCP/UDP LB",
    displayField: "name",
    icon: "../../GCP/lb.svg",
    priority: 100,
    schema: internalTCPUDPLBSchema,
  },
  gke: {
    allowedChildren: [],
    displayName: "GKE",
    displayField: "name",
    icon: "../../GCP/gke.svg",
    priority: 100,
    schema: gkeSchema,
  },
  bq: {
    allowedChildren: [],
    displayName: "BQ",
    displayField: "dataset_name",
    icon: "../../GCP/bq.svg",
    schema: bqSchema,
    priority: 100,
    uiSchema: {
      tables: {
        items: {
          schema: {
            "ui:widget": "textarea",
          },
        },
      },
      views: {
        items: {
          query: {
            "ui:widget": "textarea",
          },
        },
      },
    },
  },
};

export function getType(displayName) {
  for (const key in gcpResourceConfigs) {
    if (gcpResourceConfigs[key].displayName === displayName) {
      return key;
    }
  }
  return null;
}
