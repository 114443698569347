export const instanceTemplateSchema = {
  title: "Instance Template",
  type: "object",
  properties: {
    name_prefix: {
      title: "Name prefix for the instance template",
      type: "string",
      default: "default-instance-template",
    },
    service_account: {
      title: "Service Account",
      type: "object",
      properties: {
        email: {
          type: "string",
        },
        scopes: {
          type: "array",
          items: {
            type: "string",
          },
          default: ["cloud-platform"],
        },
      },
    },
    machine_type: {
      title: "Machine Type",
      type: "string",
      default: "e2-standard-2",
    },
    disk_size_gb: {
      title: "Boot Disk Size in GB",
      type: "number",
      default: 50,
    },
    disk_type: {
      title: "Disk Type",
      enum: ["pd-ssd", "pd-standard", "local-ssd"],
      type: "string",
      default: "pd-standard",
    },
    disk_encryption_key: {
      title: "Disk Encryption Key",
      description:
        "The self link of the encryption key that is stored in Google Cloud KMS to use to encrypt all the disks on this instance",
      type: "string",
      default: "",
    },
    source_image: {
      title: "Source Image",
      description:
        "If neither Source Image nor Source Image Family is specified, defaults to the latest public CentOS image",
      default: "",
      type: "string",
    },
    source_image_family: {
      type: "string",
      title: "Source Image Family",
      description:
        "If neither Source Image nor Source Image Family is specified, defaults to the latest public CentOS image",
      default: "centos-7",
    },
    source_image_project: {
      type: "string",
      title: "Source Image Project",
      description:
        "Project where the source image comes from. The default project contains CentOS images",
      default: "centos-cloud",
    },
    can_ip_forward: {
      type: "boolean",
      default: false,
      title: "Enable IP forwarding",
    },
    subnetwork: {
      type: "string",
      title: "Subnetwork self_link",
      description:
        "Subnetwork self_link in the format projects/<PROJECT_ID>/regions/<REGION>/subnetworks/<SUBNET_NAME>",
    },
    tags: {
      type: "array",
      title: "Network Tags",
      items: {
        type: "string",
      },
    },
    access_config: {
      type: "array",
      title: "Access Config",
      description:
        "IPs via which the VM instance can be accessed via the Internet. Leave blank for private access",
      items: {
        type: "object",
        properties: {
          nat_ip: {
            title: "NAT IP",
            type: "string",
          },
          network_tier: {
            title: "Network Tier",
            type: "string",
            enum: ["STANDARD", "PREMIUM"],
            default: "PREMIUM",
          },
        },
      },
      default: [],
    },
    startup_script: {
      type: "string",
      title: "Startup Script",
      default: "",
    },
    additional_disks: {
      title: "Additional Disks",
      type: "array",
      default: [],
      items: {
        type: "object",
        properties: {
          disk_name: {
            type: "string",
            title: "Disk Name",
          },
          disk_size: {
            type: "number",
            title: "Disk Size",
            default: 50,
          },
          device_name: {
            type: "string",
            title: "Device Name",
            description:
              "A unique device name that is reflected into the /dev/ tree of a Linux operating system running within the instance. If not specified, the server chooses a default device name to apply to this disk",
          },
          disk_type: {
            title: "Disk Type",
            enum: ["pd-ssd", "pd-standard", "local-ssd"],
            type: "string",
            default: "pd-standard",
          },
          auto_delete: {
            title: "Auto Delete",
            description: "Delete on destroy",
            type: "boolean",
            default: true,
          },
        },
      },
    },
    labels: {
      type: "array",
      title: "Labels",
      items: {
        type: "object",
        properties: {
          key: {
            type: "string",
          },
          value: {
            type: "string",
          },
        },
      },
    },
  },
  required: ["name_prefix"],
};
