export const sharedVpcSchema = {
  title: "Define network settings",
  type: "object",
  properties: {
    name: {
      title: "Network Name",
      type: "string",
    },
    subnets: {
      type: "array",
      title: "Subnets",
      items: {
        properties: {
          subnet_name: {
            title: "Subnet Name",
            type: "string",
          },
          subnet_region: {
            title: "Subnet Region",
            type: "string",
          },
          subnet_ip: {
            title: "Subnet IP range",
            type: "string",
          },
          subnet_flow_logs: {
            title: "Subnet Flow Logs",
            type: "boolean",
            default: false,
          },
          secondary_ranges: {
            type: "array",
            title: "Secondary Ranges",
            items: {
              properties: {
                range_name: {
                  title: "Range Name",
                  type: "string",
                },
                ip_cidr_range: {
                  title: "IP Range",
                  type: "string",
                },
              },
            },
          },
        },
      },
    },
    routes: {
      title: "Route Definitions",
      type: "array",
      items: {
        type: "object",
        properties: {
          name: {
            title: "Name",
            type: "string",
          },
          description: {
            title: "Description",
            type: "string",
          },
          destination_range: {
            title: "Destination Range",
            type: "string",
          },
          next_hop_ip: {
            title: "Next hop IP",
            type: "string",
          },
          next_hop_internet: {
            title: "Next hop internet?",
            type: "boolean",
            default: false,
          },
          tags: {
            type: "array",
            title: "Tags",
            items: {
              type: "string",
            },
          },
        },
      },
    },
  },
  required: ["name"],
};
