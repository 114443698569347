import React, { useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { db } from "../firebase";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://markodevops.com/">
        markodevops.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInSide() {
  const classes = useStyles();
  const { currentUser, googleUser, signIn, signInFirebase } = useAuth();
  let history = useHistory();
  useEffect(() => {
    if (currentUser) {
      const callAsync = async () => {
        await createNewUserIfNecessary();
      };
      callAsync();
      history.push("/dashboard");
    }
    // eslint-disable-next-line
  }, [currentUser]);

  const createNewUserIfNecessary = async () => {
    console.log(currentUser);
    try {
      let docRef = await db.collection("users").doc(currentUser.uid).get();
      if (!docRef.exists) {
        await db
          .collection("users")
          .doc(currentUser.uid)
          .set({ googleId: googleUser.googleId });
      }
    } catch {
      console.log("Error");
    }
  };

  const handleSignIn = async () => {
    try {
      //await signInWithGoogle();
      await signIn();
      await signInFirebase(googleUser.accessToken);
      await createNewUserIfNecessary();
      history.push("/dashboard");
    } catch {
      console.log("error");
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <button onClick={handleSignIn} className="button">
            <img src="google.svg" alt="google login" className="icon"></img>
            <span className="buttonText">Sign in with Google</span>
          </button>
          <Box mt={5}>
            <Copyright />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}
