export const publicIPSchema = {
  title: "Public IP Reservation",
  type: "object",
  properties: {
    names: {
      title: "IP Names",
      type: "array",
      items: {
        type: "string",
      },
    },
    ip_version: {
      type: "string",
      title: "IP Version",
      enum: ["IPv4", "IPv6"],
      default: "IPv4",
    },
  },
  required: ["name"],
};
