import React, { createContext, useContext, useState, useEffect } from "react";
import { auth } from "../../src/firebase";
import { useGoogleLogin } from "react-use-googlelogin";
import "firebase/auth";
import firebase from "firebase/app";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  const googleAuth = useGoogleLogin({
    // This clientID is from the projectID: devops-grl-test. This needs to be added to firebase safelist.
    // Go to firebase UI and go to authentication. Click on Google Idp. Scroll down and add this clientID
    clientId:
      "46295830776-cei9vh71837ccnp8eplte54sfjkn15rs.apps.googleusercontent.com", // Your clientID from Google.
    scope: "profile email https://www.googleapis.com/auth/cloud-platform",
  });

  const signInFirebase = async (accessToken) => {
    const cred = firebase.auth.GoogleAuthProvider.credential(null, accessToken);
    await firebase.auth().signInWithCredential(cred).catch(console.error);
  };

  const signOutFirebase = () => {
    auth
      .signOut()
      .then(() => {})
      .catch((error) => {
        alert("Failed to signout. Error:\n" + JSON.stringify(error));
      });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signOutFirebase,
    signInFirebase,
    loading,
    // Unpack googleAuth so we have acccess to googleUser, isSignedIn and so on
    ...googleAuth,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
