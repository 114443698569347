export const firewallsSchema = {
  type: "object",
  title: "Firewall Rules",
  properties: {
    firewall_rules: {
      type: "array",
      title: "Firewall Rules",
      items: {
        title: "Rule",
        type: "object",
        properties: {
          name: {
            title: "Firewall Name",
            type: "string",
          },
          direction: {
            title: "Direction",
            type: "string",
            enum: ["INGRESS", "EGRESS"],
            default: "INGRESS",
          },
          priority: {
            title: "Priority",
            type: "number",
            default: 1000,
          },
          allow: {
            title: "Allow ports",
            type: "array",
            items: {
              properties: {
                protocol: {
                  title: "Protocol",
                  type: "string",
                },
                ports: {
                  title: "Ports and port ranges",
                  description: "Comma separated list",
                  type: "string",
                  examples: ["1000-2000", "443,80,22", "8080-8090,443"],
                },
              },
            },
          },
          deny: {
            title: "Deny ports",
            type: "array",
            items: {
              properties: {
                protocol: {
                  title: "Protocol",
                  type: "string",
                },
                ports: {
                  title: "Ports and port ranges",
                  description: "Comma separated list",
                  type: "string",
                  examples: ["1000-2000", "443,80,22", "8080-8090,443"],
                },
              },
            },
          },
          source_tags: {
            title: "Source Tags",
            type: "array",
            items: {
              type: "string",
            },
          },
          source_service_accounts: {
            title: "Source Service Accounts",
            type: "array",
            items: { type: "string" },
          },
          target_tags: {
            title: "Target Tags",
            type: "array",
            items: { type: "string" },
          },
          target_service_accounts: {
            title: "Target Service Accounts",
            type: "array",
            items: { type: "string" },
          },
        },
      },
    },
  },
};
