import { React, useState, useEffect } from "react";
import clsx from "clsx";
//Material-UI
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import Popover from "@material-ui/core/Popover";
import DialogActions from "@material-ui/core/DialogActions";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";

//Routing
import { useHistory } from "react-router-dom";
//UserDefined
import PrivateRoute from "./PrivateRoute";
import Profile from "./Profile";
import MainListItems from "./SideMenuItems";
import Organizations from "./Organization";
//import { secondaryListItems } from "./SideMenuItems";
import Environments from "./Environments";
import { useAuth } from "../contexts/AuthContext";
import Pricing from "./Pricing";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://markodevops.com/">
        Marko DevOps
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    display: "flex", //added by Marko
    flexDirection: "column",
    height: "fit-content",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  avatarPopoverMenu: {
    padding: theme.spacing(2),
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  // This state us used to control side menu Drawer
  const [open, setOpen] = useState(true);
  // Used for Sign Out menu
  const [anchorEl, setAnchorEl] = useState(null);

  // User defined
  const { currentUser, signOutFirebase } = useAuth();

  const openAvatarMenu = Boolean(anchorEl);
  const openAvatarMenuId = openAvatarMenu ? "simple-popover" : undefined;

  const handleAvatarButtonClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleAvatarButtonClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async (event) => {
    event.preventDefault();
    console.log("SignOut");
    await signOutFirebase();
    history.push("/");
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!currentUser) {
      history.push("/");
    }
    // Ensure that we load something into the dashboard
    history.push("/dashboard/profile");
    //history.push("/dashboard/environments");
    // eslint-disable-next-line
  }, []);
  let history = useHistory();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            GCP Terraform Deployer
          </Typography>
          <IconButton color="inherit" onClick={handleAvatarButtonClick}>
            <Avatar src={currentUser.photoURL} />
          </IconButton>
          <Popover
            id={openAvatarMenuId}
            open={openAvatarMenu}
            anchorEl={anchorEl}
            onClose={handleAvatarButtonClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Box
              className={classes.avatarPopoverMenu}
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="300"
            >
              <Box
                className={classes.avatarPopoverMenu}
                display="flex"
                alignItems="center"
                padding="2"
              >
                <Avatar src={currentUser.photoURL} />
              </Box>
              <Box
                className={classes.avatarPopoverMenu}
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                <Typography variant="h6" component="h2">
                  {currentUser.displayName}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {currentUser.email}
                </Typography>
              </Box>
              <Box
                className={classes.avatarPopoverMenu}
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                <DialogActions>
                  <Button
                    className={classes.avatarPopoverMenu}
                    variant="contained"
                    color="primary"
                    style={{ height: 40 }}
                    onClick={handleSignOut}
                  >
                    Sign Out
                  </Button>
                </DialogActions>
              </Box>
            </Box>
          </Popover>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <MainListItems />
        </List>
        <Divider />
        {/*<List>{secondaryListItems}</List>*/}
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <PrivateRoute path="/dashboard/profile" exact component={Profile} />
        <PrivateRoute
          path="/dashboard/organizations"
          exact
          component={Organizations}
        />
        <PrivateRoute
          path="/dashboard/environments"
          exact
          component={Environments}
        />
        <PrivateRoute path="/dashboard/billing" exact component={Pricing} />
        <Box pt={4}>
          <Copyright />
        </Box>
      </main>
    </div>
  );
}
